document.addEventListener('DOMContentLoaded', function () {
  // работа дровера
  if (
    document.getElementById('headerBtn') &&
    document.querySelector('.blackscreen') &&
    document.querySelector('.header__drawer')
  ) {
    const headerBtn = document.getElementById('headerBtn')
    const blackscreen = document.querySelector('.blackscreen')
    const drawer = document.querySelector('.header__drawer')

    // Показываем дровер
    function showDrawer() {
      drawer.style.display = 'block'

      setTimeout(() => {
        blackscreen.classList.add('blackscreen--active')
        headerBtn.classList.add('header__menu--active')
        drawer.classList.add('header__drawer--active')
      }, 100)
    }

    // Убираем дровер
    function hideDrawer() {
      blackscreen.classList.remove('blackscreen--active')
      headerBtn.classList.remove('header__menu--active')
      drawer.classList.remove('header__drawer--active')

      setTimeout(() => {
        drawer.style.display = 'none'
      }, 500)
    }

    // Обработка клика на гамбургер
    headerBtn.addEventListener('click', function () {
      if (this.classList.contains('header__menu--active')) {
        hideDrawer()
      } else {
        showDrawer()
      }
    })

    blackscreen.addEventListener('click', function () {
      if (this.classList.contains('blackscreen--active')) {
        hideDrawer()
      } else {
        showDrawer()
      }
    })
  }

  // проверяем форму регистрации
  if (document.querySelector('.register__form')) {
    const registerForm = document.querySelector('.register__form')
    const registerEmail = registerForm.querySelector('.register__email')
    const registerPass1 = registerForm.querySelector('.register__pass1')
    const registerPass2 = registerForm.querySelector('.register__pass2')
    const registerCheckbox = registerForm.querySelector('.register__checkbox')
    const registerFormBtn = registerForm.querySelector('.register__btn')

    setInterval(function () {
      // валидация полей
      if (
        registerEmail.value === '' ||
        !registerEmail.value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        registerEmail.classList.add('register__empty')
      } else {
        registerEmail.classList.remove('register__empty')
      }

      if (
        registerPass1.value === '' ||
        registerPass1.value.length < 8 ||
        registerPass1.value.length > 50 ||
        registerPass1.value.match(/^\d+$/)
      ) {
        registerPass1.classList.add('register__empty')
      } else {
        registerPass1.classList.remove('register__empty')
      }

      if (
        registerPass2.value === '' ||
        registerPass2.value.length < 8 ||
        registerPass2.value.length > 50 ||
        registerPass2.value.match(/^\d+$/)
      ) {
        registerPass2.classList.add('register__empty')
      } else {
        if (registerPass1.value === registerPass2.value) {
          registerPass2.classList.remove('register__empty')
        } else {
          registerPass2.classList.add('register__empty')
        }
      }

      if (!registerCheckbox.checked) {
        registerCheckbox.classList.add('register__empty')
      } else {
        registerCheckbox.classList.remove('register__empty')
      }

      const sizeEmpty = registerForm.querySelectorAll('.register__empty').length

      if (sizeEmpty > 0) {
        if (registerFormBtn.classList.contains('register__btn--disabled')) {
          return
        } else {
          registerFormBtn.classList.add('register__btn--disabled')
        }
      } else {
        registerFormBtn.classList.remove('register__btn--disabled')
      }
    }, 500)
  }

  // проверяем форму входа
  if (document.querySelector('.login__form')) {
    const loginForm = document.querySelector('.login__form')
    const loginEmail = loginForm.querySelector('.login__email')
    const loginPassword = loginForm.querySelector('.login__password')
    const loginFormBtn = loginForm.querySelector('.login__btn')

    setInterval(function () {
      // валидация полей
      if (
        loginEmail.value === '' ||
        !loginEmail.value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        loginEmail.classList.add('login__empty')
      } else {
        loginEmail.classList.remove('login__empty')
      }

      if (
        loginPassword.value === '' ||
        loginPassword.value.length < 8 ||
        loginPassword.value.length > 50 ||
        loginPassword.value.match(/^\d+$/)
      ) {
        loginPassword.classList.add('login__empty')
      } else {
        loginPassword.classList.remove('login__empty')
      }

      const sizeEmpty = loginForm.querySelectorAll('.login__empty').length

      if (sizeEmpty > 0) {
        if (loginFormBtn.classList.contains('login__btn--disabled')) {
          return
        } else {
          loginFormBtn.classList.add('login__btn--disabled')
        }
      } else {
        loginFormBtn.classList.remove('login__btn--disabled')
      }
    }, 500)
  }

  // проверяем форму смены пароля
  if (document.querySelector('.password__form')) {
    const passwordForm = document.querySelector('.password__form')
    const passwordOld = passwordForm.querySelector('.password__old')
    const passwordNew1 = passwordForm.querySelector('.password__new1')
    const passwordNew2 = passwordForm.querySelector('.password__new2')
    const passwordFormBtn = passwordForm.querySelector('.password__btn')

    setInterval(function () {
      // валидация полей
      if (
        passwordOld.value === '' ||
        passwordOld.value.length < 8 ||
        passwordOld.value.length > 50 ||
        passwordOld.value.match(/^\d+$/)
      ) {
        passwordOld.classList.add('password__empty')
      } else {
        passwordOld.classList.remove('password__empty')
      }

      if (
        passwordNew1.value === '' ||
        passwordNew1.value.length < 8 ||
        passwordNew1.value.length > 50 ||
        passwordNew1.value.match(/^\d+$/) ||
        passwordNew1.value === passwordOld.value
      ) {
        passwordNew1.classList.add('password__empty')
      } else {
        passwordNew1.classList.remove('password__empty')
      }

      if (
        passwordNew2.value === '' ||
        passwordNew2.value.length < 8 ||
        passwordNew2.value.length > 50 ||
        passwordNew2.value.match(/^\d+$/) ||
        passwordNew2.value === passwordOld.value
      ) {
        passwordNew2.classList.add('password__empty')
      } else {
        if (passwordNew1.value === passwordNew2.value) {
          passwordNew2.classList.remove('password__empty')
        } else {
          passwordNew2.classList.add('password__empty')
        }
      }

      const sizeEmpty = passwordForm.querySelectorAll('.password__empty').length

      if (sizeEmpty > 0) {
        if (passwordFormBtn.classList.contains('password__btn--disabled')) {
          return
        } else {
          passwordFormBtn.classList.add('password__btn--disabled')
        }
      } else {
        passwordFormBtn.classList.remove('password__btn--disabled')
      }
    }, 500)
  }

  // проверяем форму сброса пароля
  if (document.querySelector('.password__form--reset')) {
    const passwordForm = document.querySelector('.password__form--reset')
    const passwordEmail = passwordForm.querySelector('.password__email')
    const passwordFormBtn = passwordForm.querySelector('.password__btn')

    setInterval(function () {
      //   // валидация полей
      if (
        passwordEmail.value === '' ||
        !passwordEmail.value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        passwordEmail.classList.add('password__empty')
      } else {
        passwordEmail.classList.remove('password__empty')
      }

      const sizeEmpty = passwordForm.querySelectorAll('.password__empty').length

      if (sizeEmpty > 0) {
        if (passwordFormBtn.classList.contains('password__btn--disabled')) {
          return
        } else {
          passwordFormBtn.classList.add('password__btn--disabled')
        }
      } else {
        passwordFormBtn.classList.remove('password__btn--disabled')
      }
    }, 500)
  }

  // проверяем форму нового пароля
  if (document.querySelector('.password__form--confirm')) {
    const passwordForm = document.querySelector('.password__form--confirm')
    const passwordNew1 = passwordForm.querySelector('.password__new1')
    const passwordNew2 = passwordForm.querySelector('.password__new2')
    const passwordFormBtn = passwordForm.querySelector('.password__btn')

    setInterval(function () {
      //   // валидация полей
      if (
        passwordNew1.value === '' ||
        passwordNew1.value.length < 8 ||
        passwordNew1.value.length > 50 ||
        passwordNew1.value.match(/^\d+$/)
      ) {
        passwordNew1.classList.add('password__empty')
      } else {
        passwordNew1.classList.remove('password__empty')
      }

      if (
        passwordNew2.value === '' ||
        passwordNew2.value.length < 8 ||
        passwordNew2.value.length > 50 ||
        passwordNew2.value.match(/^\d+$/)
      ) {
        passwordNew2.classList.add('password__empty')
      } else {
        if (passwordNew1.value === passwordNew2.value) {
          passwordNew2.classList.remove('password__empty')
        } else {
          passwordNew2.classList.add('password__empty')
        }
      }

      const sizeEmpty = passwordForm.querySelectorAll('.password__empty').length

      if (sizeEmpty > 0) {
        if (passwordFormBtn.classList.contains('password__btn--disabled')) {
          return
        } else {
          passwordFormBtn.classList.add('password__btn--disabled')
        }
      } else {
        passwordFormBtn.classList.remove('password__btn--disabled')
      }
    }, 500)
  }

  // управляем сообщениями
  if (document.querySelector('.messages')) {
    const message = document.querySelector('.messages')
    const messageBtn = document.querySelector('.messages__cross')

    message.classList.add('messages--active')

    messageBtn.addEventListener('click', function () {
      message.classList.remove('messages--active')
    })

    setTimeout(() => {
      message.classList.remove('messages--active')
    }, 10000)
  }

  // управление радиокнопками
  if (document.querySelectorAll('.add__form__radio input')) {
    const radioInputs = document.querySelectorAll('.add__form__radio input')

    radioInputs.forEach(function (element) {
      element.addEventListener('click', function () {
        window.location.href = `/dashboard/add/${element.value}`
      })
    })
  }

  // валидация формы объявления
  if (document.querySelector('.add__form')) {
    const form = document.querySelector('.add__form form')
    const textarea = form.querySelector('#createFormDescription')
    const counter = form.querySelector('.add__form__description span')

    const addFormInputs = form.querySelectorAll('.add__form__input')
    const addFormBtn = form.querySelector('.add__form__btn')

    counter.textContent = 1000 - textarea.value.length

    textarea.addEventListener('keyup', function () {
      counter.textContent = 1000 - this.value.length
    })

    setInterval(function () {
      addFormInputs.forEach(function (element) {
        if (element.value === '' || element.value.length <= 1 || element.value.length > 1000) {
          element.classList.add('add__form__input--empty')
        } else {
          element.classList.remove('add__form__input--empty')
        }

        const sizeEmpty = form.querySelectorAll('.add__form__input--empty').length

        if (sizeEmpty > 0) {
          if (addFormBtn.classList.contains('add__form__btn--disabled')) {
            return
          } else {
            addFormBtn.classList.add('add__form__btn--disabled')
          }
        } else {
          addFormBtn.classList.remove('add__form__btn--disabled')
        }
      })
    }, 500)
  }

  // форма выбора услуг
  if (document.querySelector('.service__form')) {
    const form = document.querySelector('.service__form form')
    const formTime = form.querySelector('#serviceFormTime')
    const formPosition = form.querySelector('#serviceFormPosition')
    const serviceTotal = form.querySelector('.service__total p span')
    const serviceMessagesAdd = form.querySelector('.service__messages--add')
    const serviceBtn = form.querySelector('.service__btn')

    formTime.addEventListener('change', function () {
      serviceTotal.innerText = Number(this.value) + Number(formPosition.value)

      switch (this.options[this.selectedIndex].getAttribute('name')) {
        case 'week':
          if (document.querySelector('.service__messages--active')) {
            let now = new Date()

            let activeDate = document.querySelector('.service__messages--active span').textContent
            activeDate = activeDate.split('.')

            now = new Date(activeDate[2], activeDate[1] - 1, activeDate[0])
            now.setDate(now.getDate() + 7)

            serviceMessagesAdd.innerText = `Ваше объявление будет продлено до: ${now.toLocaleDateString()}`
          } else {
            let now = new Date()

            now.setDate(now.getDate() + 7)
            serviceMessagesAdd.innerText = `Ваше объявление будет продлено до: ${now.toLocaleDateString()}`
          }
          break
        case 'month':
          if (document.querySelector('.service__messages--active')) {
            let now = new Date()

            let activeDate = document.querySelector('.service__messages--active span').textContent
            activeDate = activeDate.split('.')

            now = new Date(activeDate[2], activeDate[1] - 1, activeDate[0])
            now.setMonth(now.getMonth() + 1)

            serviceMessagesAdd.innerText = `Ваше объявление будет продлено до: ${now.toLocaleDateString()}`
          } else {
            let now = new Date()

            now.setMonth(now.getMonth() + 1)
            serviceMessagesAdd.innerText = `Ваше объявление будет продлено до: ${now.toLocaleDateString()}`
          }
          break
        case 'quarter':
          if (document.querySelector('.service__messages--active')) {
            let now = new Date()

            let activeDate = document.querySelector('.service__messages--active span').textContent
            activeDate = activeDate.split('.')

            now = new Date(activeDate[2], activeDate[1] - 1, activeDate[0])
            now.setMonth(now.getMonth() + 3)
            now.setDate(now.getDate() + 14)

            serviceMessagesAdd.innerText = `Ваше объявление будет продлено до: ${now.toLocaleDateString()}`
          } else {
            let now = new Date()

            now.setMonth(now.getMonth() + 3)
            now.setDate(now.getDate() + 14)
            serviceMessagesAdd.innerText = `Ваше объявление будет продлено до: ${now.toLocaleDateString()}`
          }
          break
        default:
          serviceMessagesAdd.innerText = ''
      }
    })

    formPosition.addEventListener('change', function () {
      serviceTotal.innerText = Number(this.value) + Number(formTime.value)
    })

    setInterval(function () {
      if (formTime.value === '0' && formPosition.value === '0') {
        serviceBtn.classList.add('service__btn--disabled')
      } else {
        serviceBtn.classList.remove('service__btn--disabled')
      }
    }, 500)
  }

  // форма оплаты
  if (document.querySelector('.balance__form')) {
    const form = document.querySelector('.balance__form form')
    const formBalance = form.querySelector('#formBalance')
    const formBtn = form.querySelector('.balance__form__btn')

    setInterval(function () {
      if (
        formBalance.value === '' ||
        formBalance.value < 500 ||
        !formBalance.value.match(/^\d+$/)
      ) {
        formBtn.classList.add('balance__form__btn--disabled')
      } else {
        formBtn.classList.remove('balance__form__btn--disabled')
      }
    }, 500)
  }

  // форма платежа
  if (document.querySelector('.payment__form')) {
    const form = document.querySelector('.payment__form form')
    const formCheckbox = form.querySelector('.payment__form__checkbox input')
    const formBtn = form.querySelector('.payment__form__btn')

    setInterval(function () {
      if (formCheckbox.checked) {
        formBtn.classList.remove('payment__form__btn--disabled')
      } else {
        formBtn.classList.add('payment__form__btn--disabled')
      }
    }, 500)
  }

  // курсы валют
  if (document.querySelector('.payment')) {
    try {
      // Определяем общую переменную
      const rubField = document.querySelector('.payment__rub').innerText.replace(/[^\d]/g, '')

      // Считаем BTC
      fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/btc.json')
        .then(response => response.json())
        .then(data => {
          const btcToRub = data.btc.rub.toFixed(2)
          const btcField = document.querySelector('.payment__btc')
          const formBtc = document.getElementById('formBtc')
          btcField.innerText = (+rubField / btcToRub).toFixed(5)
          formBtc.value = (+rubField / btcToRub).toFixed(5)
        })

      // Считаем TRX
      fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/trx.json')
        .then(response => response.json())
        .then(data => {
          const trxToRub = data.trx.rub.toFixed(2)
          const trxField = document.querySelector('.payment__trx')
          const formTrx = document.getElementById('formTrx')
          trxField.innerText = (+rubField / trxToRub).toFixed(2)
          formTrx.value = (+rubField / trxToRub).toFixed(2)
        })

      // Считаем TON
      fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/ton.json')
        .then(response => response.json())
        .then(data => {
          const tonToRub = data.ton.rub.toFixed(2)
          const tonField = document.querySelector('.payment__ton')
          const formTon = document.getElementById('formTon')
          tonField.innerText = (+rubField / tonToRub).toFixed(2)
          formTon.value = (+rubField / tonToRub).toFixed(2)
        })

      // Считаем ETH
      fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/eth.json')
        .then(response => response.json())
        .then(data => {
          const ethToRub = data.eth.rub.toFixed(2)
          const ethField = document.querySelector('.payment__eth')
          const formEth = document.getElementById('formEth')
          ethField.innerText = (+rubField / ethToRub).toFixed(5)
          formEth.value = (+rubField / ethToRub).toFixed(5)
        })

      // Считаем USDT
      fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usdt.json')
        .then(response => response.json())
        .then(data => {
          const usdtToRub = data.usdt.rub.toFixed(2)
          const usdtField = document.querySelector('.payment__usdt')
          const formUsdt = document.getElementById('formUsdt')
          usdtField.innerText = (+rubField / usdtToRub).toFixed(2)
          formUsdt.value = (+rubField / usdtToRub).toFixed(2)
        })

      // Считаем USDC
      fetch('https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usdc.json')
        .then(response => response.json())
        .then(data => {
          const usdcToRub = data.usdc.rub.toFixed(2)
          const usdcField = document.querySelector('.payment__usdc')
          const formUsdc = document.getElementById('formUsdc')
          usdcField.innerText = (+rubField / usdcToRub).toFixed(2)
          formUsdc.value = (+rubField / usdcToRub).toFixed(2)
        })
    } catch (err) {
      console.log(err)
    }
  }

  // устанавливаем таймер
  if (document.querySelector('.payment')) {
    function countdown(seconds) {
      seconds = parseInt(sessionStorage.getItem('seconds')) || seconds

      function tick() {
        seconds--
        sessionStorage.setItem('seconds', seconds)
        var counter = document.getElementById('paymentTimer')
        var current_minutes = parseInt(seconds / 60)
        var current_seconds = seconds % 60
        counter.innerHTML =
          current_minutes + ':' + (current_seconds < 10 ? '0' : '') + current_seconds
        if (seconds > 0) {
          setTimeout(tick, 1000)
        } else {
          window.location.href = '/dashboard'
        }
      }
      tick()
    }

    countdown(900)
  } else {
    sessionStorage.removeItem('seconds')
  }
})
